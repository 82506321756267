<template>
  <div id="page-detail-pemeriksaan-lab">
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="4" lg="4">
          <b-card>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table-simple style="margin-bottom: 0;" class="table-info-pasien">
                  <b-tbody>
                    <!-- <b-tr>
                      <b-td style="width:100px">ID</b-td>
                      <b-td style="width:15px">:</b-td>
                      <b-td>-</b-td>
                    </b-tr> -->

                    <b-tr>
                      <b-td>No. Antrean</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.no_antrian }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-td>Tanggal</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ $moment(data_pasien.tanggal_antrian).format("DD-MM-YYYY") }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-td>ID Pendaftaran</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.no_pendaftaran }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-td>Penanggung Jawab</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pemeriksaan.nama_penanggung_jawab }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-td>Pemeriksa</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pemeriksaan.nama_pemeriksa }}</b-td>
                    </b-tr>


                    <b-tr>
                      <b-td>No. eRM</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.no_rm }}</b-td>
                    </b-tr>


                    <b-tr>
                      <b-td>NIK</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.nik }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-td>Nama Pasien</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.nama_lengkap }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-td>Tempat, Tgl Lahir</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.ttl }}</b-td>
                    </b-tr>

                    <!-- <b-tr>
                      <b-td>Umur</b-td>
                      <b-td>:</b-td>
                      <b-td>-</b-td>
                    </b-tr> -->

                    <b-tr>
                      <b-td>Jenis Kelamin</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.jenis_kelamin }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-td>Alamat</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.alamat_sekarang }}</b-td>
                    </b-tr>

                    <!-- <b-tr>
                      <b-td>Asuransi</b-td>
                      <b-td>:</b-td>
                      <b-td>-</b-td>
                    </b-tr> -->

                    <b-tr>
                      <b-td>Dibuat pada / oleh</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.dibuat_oleh }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-td>Tanggal Selesai</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.tgl_selesai }}</b-td>
                    </b-tr>

                    <!-- <b-tr>
                      <b-td>IDibuat pada / oleh</b-td>
                      <b-td>:</b-td>
                      <b-td>-</b-td>
                    </b-tr> -->
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col cols="12" md="8" lg="8">
          <b-card>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <h6 class="custom-title-color-black">Data Paket Pemeriksaan Laboratorium</h6>
              </b-col>
            </b-row>

            <b-row style="margin-top:15px;">
              <b-col cols="12" md="12" lg="12">
                <b-table-simple hover striped small style="margin-bottom: 0;" class="custom-table">
                  <b-thead>
                    <b-tr>
                      <b-th>Pemeriksaan</b-th>
                      <b-th>Tarif</b-th>
                      <b-th>Nilai</b-th>
                      <b-th class="table-option-3">Hasil Pemeriksaan</b-th>
                      <b-th>Satuan</b-th>
                      <b-th>Sample</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody v-if="list_pemeriksaan.length">
                    <b-tr v-for="(item, idx) in list_pemeriksaan" :key="idx">
                      <b-td><b>{{ item.nama_sub_pemeriksaan_lab }}</b></b-td>
                      <b-td>{{ item.harga_sub_pemeriksaan_lab }}</b-td>
                      <b-td v-if="item.hasil_pemeriksaan">{{ item.hasil_pemeriksaan }}</b-td>
                      <b-td v-else>{{ item.hasil_pemeriksaan_text }}</b-td>
                      <b-td>{{ item.nilai_pemeriksaan }}</b-td>
                      <b-td>{{ item.satuan_pemeriksaan }}</b-td>
                      <b-td>{{ item.nama_sampel }}</b-td>
                    </b-tr>
                  </b-tbody>
                  <b-tbody v-else>
                    <b-tr v-for="(item, idx) in list_kunjungan_lab" :key="idx">
                      <b-td><b>{{ item.nama_sub_tindakan_lab }}</b></b-td>
                      <b-td>{{ item.harga_sub_lab_kunjungan }}</b-td>
                      <b-td>-</b-td>
                      <b-td>-</b-td>
                      <b-td>-</b-td>
                      <b-td>-</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>        
              </b-col>
            </b-row>
          </b-card>

          <b-row>
              <b-col cols="12" md="12" lg="12">
                <div style="width: 100%;display: flex;justify-content: flex-end;">
                  <b-button v-if="!data_pemeriksaan.pemeriksaan_lab_id" :disabled="busy" variant="warning" class="mr-1" @click="pindahInput()">
                    <b-spinner v-if="busy" variant="light" label="Spinning"></b-spinner>
                    <span v-else>Mulai</span>
                  </b-button>
                  <b-button v-else variant="dark" :disabled="disabledMulai" class="mr-1" @click="goInput()">Update</b-button>
                  <b-button variant="dark" class="mr-1">Panggil</b-button>
                  <b-button variant="success" class="mr-1" @click="selesaiPemeriksaan()">Selesai</b-button>
                  <b-button variant="info" class="mr-1" @click="printLab('hasil_pemeriksaan')">Cetak</b-button>
                  <b-button variant="primary" :disabled="data_pemeriksaan.pemeriksaan_lab_id" v-b-modal.modal-tindakan-lab class="mr-1">Tindakan</b-button>
                </div>
              </b-col>
            </b-row>

          <b-card style="margin-top:30px;">
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <h6 class="custom-title-color-black">Data Pengambilan Sampel</h6>
              </b-col>
            </b-row>

            <b-row style="margin-top:15px;">
              <b-col cols="12" md="12" lg="12">
                <b-table-simple hover striped small style="margin-bottom: 0;" class="custom-table">
                  <b-thead>
                    <b-tr>
                      <b-th>Tanggal Pengambilan</b-th>
                      <b-th>Batas Waktu</b-th>
                      <b-th>Jenis Sampe</b-th>
                      <b-th>Jumlah</b-th>
                      <b-th>Takaran</b-th>
                      <b-th>Catatan</b-th>
                      <!-- <b-th>Digunakan</b-th> -->
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(item, idx) in data_sampel" :key="idx">
                      <b-td>{{ $moment(item.tanggal_sampel).format("DD-MM-YYYY HH:mm") }}</b-td>
                      <b-td>{{ $moment(item.batas_waktu_sampel).format("DD-MM-YYYY") }}</b-td>
                      <b-td>{{ item.nama_sampel }}</b-td>
                      <b-td>{{ item.satuan_takaran }}</b-td>
                      <b-td>{{ item.jumlah }}</b-td>
                      <b-td>{{ item.catatan_sampel_lab }}</b-td>
                      <!-- <b-td>
                        <div style="display:flex;justify-content:center">
                          <b-button @click="deleteSampel(item)" variant="warning" class="mr-2"><CIcon name="cil-trash" /></b-button>
                        </div>
                      </b-td> -->
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card>
        </b-col>



        

        <b-col cols="12" md="12" lg="12">
          
        </b-col>

      </b-row>
    </b-container>

    <!-- modal tindakan lab -->
    <b-modal id="modal-tindakan-lab" size="lg" title="Input Tindakan">
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <h6 class="custom-title-color-black">Paket Pemeriksaan Laboratorium</h6>
        </b-col>
      </b-row>

      <b-row>
        <b-col ref="itikiwir" cols="12" md="12" lg="6" xl="6" v-for="(item, idx) in list_paket" :key="idx">
          <b-button v-if="item.checked == false" variant="outline-dark" size="sm" @click="assignData(item)"><CIcon name="cil-plus" /> {{ item.nama_paket_lab }}</b-button>
          <b-button v-else variant="primary" size="sm" @click="assignData(item)"><CIcon name="cil-check-alt" /> {{ item.nama_paket_lab }}</b-button>
          <!-- <p>{{ item.nama_paket_lab }}</p> -->
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12">
          <hr/>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12">
          <h6 class="custom-title-color-black">Laboratorium</h6>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12">
          <b-card-group columns>
            <b-card style="border:1px solid #D3D3D3;" v-for="(tindakan) in list_tindakan" :key="tindakan.tindakan_laboratorium_id">
              <b-form-checkbox
                :id="tindakan.id"
                class="mt-1 title-checkbox"
                v-model="tindakan.checked"
                @change="toggleSelect(tindakan)"
              >{{ tindakan.nama_tindakan_laboratorium }}</b-form-checkbox>

              <div v-for="(sub) in tindakan.bulk_sub_tindakan_lab" :key="sub.sub_tindakan_lab_id">
                <b-form-checkbox
                  :id="sub.sub_tindakan_lab_id"
                  class="mt-1"
                  v-model="sub.checked"
                  ref="subTindakan"
                  :disabled="sub.disabled"
                  @change="setData()"
                ><span class="font-checkbox">{{ sub.nama_sub_tindakan_lab }}</span></b-form-checkbox>
              </div>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12">
          <hr/>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12">
          <b-table-simple hover striped style="margin-bottom: 0;">
            <b-thead>
              <b-tr>
                <b-th>Pemeriksaan</b-th>
                <b-th>Tarif</b-th>
                <b-th>Nilai</b-th>
                <b-th>Hasil Pemeriksaan</b-th>
                <b-th>Sample</b-th>
              </b-tr>
            </b-thead>

            <b-tbody>
              <b-tr v-for="(item, idx) in tampungan" :key="idx">
                <b-td v-if="item.is_paket == true"><b>{{ item.nama_paket_lab }}</b></b-td>
                <b-td v-else style="padding-left:30px;">{{ item.nama_sub_tindakan_lab }}</b-td>
                <b-td v-if="item.is_paket == true">{{ item.harga_paket_lab }}</b-td>
                <b-td v-else>{{ item.harga_tindakan_lab }}</b-td>
                <b-td>-</b-td>
                <b-td>-</b-td>
                <b-td>-</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-modal>

    <!-- PRINT HASIL PEMERIKSAAN -->
    <div style="display: none;" id="hasil_pemeriksaan">
      <section class="content-print A4" v-if="printReady">
        <div class="sheet">
            <div class="w-100">
                <table border="0" class="custom-table">
                <tbody>
                    <tr>
                        <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img :src="logo" alt="" style="width: 100%;"></td>
                        <td style="width: 70%;vertical-align: middle;">
                            <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">PEMERINTAH {{ $store.state.puskesmas.nama_kota }} </h6>
                            <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">DINAS KESEHATAN</h6>
                            <h6 style="text-align: center;font-weight: bold;margin-bottom: 0;font-size: 16pt;">{{ $store.state.puskesmas.nama_puskesmas }} </h6>
                            <h6 style="text-align: center;font-weight: normal;font-size: 10pt;">{{ $store.state.puskesmas.alamat_puskesmas }}</h6>
                            <h6 style="text-align: center;font-weight: normal;font-size: 10pt;"><span>Email : {{ $store.state.puskesmas.email_pengaduan ? $store.state.puskesmas.email_pengaduan : "-"}}</span> <span>Telp. : {{ $store.state.puskesmas.no_telp_pengaduan ? $store.state.puskesmas.no_telp_pengaduan : "-" }}</span></h6>
                        </td>
                        <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img src="../../cetak/puskesmas-logo.png" alt="" style="width: 100%;"></td>
                    </tr>
                </tbody>
                </table>
            </div>

            <div class="w-100" style="margin-top: 2.5mm;">
                <div class="line"></div>
            </div>

            <div class="w-100" style="margin-top: 5mm;">
                <h6 style="font-size: 16pt;font-weight: bold;text-align: center;"><u>HASIL PEMERIKSAAN LABORATORIUM</u></h6>
            </div>

            <div class="w-100-flex" style="margin-top: 10mm;">
                <div style="width: 50%;">
                    <table class="custom-table">
                        <tbody>
                            <tr>
                                <td style="width: 40mm;padding-left: 0;">Dokter Pengirim</td>
                                <td style="width: 5mm;">:</td>
                                <td>{{ data_print.dokter }}</td>
                            </tr>
            
                            <tr>
                                <td style="padding-left:0">Pasien</td>
                                <td>:</td>
                                <td>{{ data_print.nama_lengkap }}</td>
                            </tr>
            
                            <tr>
                                <td style="padding-left:0">Nomor Register</td>
                                <td>:</td>
                                <td>{{ data_print.no_registrasi_lab }}</td>
                            </tr>
            
                            <tr>
                                <td style="padding-left:0">Nomor Asuransi</td>
                                <td>:</td>
                                <td>{{ data_print.no_bpjs }}</td>
                            </tr>
            
                            <tr>
                                <td style="padding-left:0">Nama</td>
                                <td>:</td>
                                <td>{{ data_print.nama_lengkap }}</td>
                            </tr>
            
                            <tr>
                                <td style="padding-left:0">NIK</td>
                                <td>:</td>
                                <td>{{ data_print.nik }}</td>
                            </tr>
    
                            <tr>
                                <td style="padding-left:0">Umur</td>
                                <td>:</td>
                                <td>
                                  <span v-if="data_print.usia_tahun">{{ data_print.usia_tahun }} Tahun</span>
                                  <span v-if="data_print.usia_bulan">{{ data_print.usia_bulan }} Bulan</span>
                                  <span v-if="data_print.usia_hari">{{ data_print.usia_hari }} Hari</span>
                                </td>
                            </tr>
    
                            <tr>
                                <td style="padding-left:0">Tanggal Lahir</td>
                                <td>:</td>
                                <td>{{ $moment(data_print.tanggal_lahir).format("LL") }}</td>
                            </tr>
    
                            <tr>
                                <td style="padding-left:0">Jenis Kelamin</td>
                                <td>:</td>
                                <td>{{ data_print.jenis_kelamin == "L" ? "Laki-laki" : data_print.jenis_kelamin == "P" ? "Perempuan" : data_print.jenis_kelamin }}</td>
                            </tr>
    
                            <tr>
                                <td style="padding-left:0">Alamat</td>
                                <td>:</td>
                                <td>{{ data_print.alamat_sekarang }}</td>
                            </tr>
    
                            <!-- <tr>
                                <td style="padding-left:0">Jenis</td>
                                <td>:</td>
                                <td>-</td>
                            </tr> -->
    
                            <tr>
                                <td style="padding-left:0">Tanggal Selesai</td>
                                <td>:</td>
                                <td>{{ $moment(data_print.tanggal_selesai_pemeriksaan_lab).format("LL") }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="width: 50%;">
                    <div class="custom-border-paper" style="padding: 2.5mm;">
                        <h6>SAMPLE</h6>
                        <table class="custom-table">
                            <tbody>
                                <tr>
                                    <td style="width: 35mm;padding-left: 0;">Nama</td>
                                    <td style="width: 5mm;">:</td>
                                    <td>{{ data_print.nama_sampel }}</td>
                                </tr>
                
                                <tr>
                                    <td style="padding-left:0">Takaran</td>
                                    <td>:</td>
                                    <td>{{ data_print.satuan_takaran }}</td>
                                </tr>
                
                                <tr>
                                    <td style="padding-left:0">Tanggal Ambil</td>
                                    <td>:</td>
                                    <td>{{ $moment(data_print.tanggal_sampel).format("LLL") }}</td>
                                </tr>
                
                                <tr>
                                    <td style="padding-left:0">Batas Waktu</td>
                                    <td>:</td>
                                    <td>{{ $moment(data_print.batas_waktu_sampel).format("LL") }}</td>
                                </tr>
                
                                <tr>
                                    <td style="padding-left:0">Petugas</td>
                                    <td>:</td>
                                    <td>{{ data_print.petugas }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="w-100" style="margin-top: 2.5mm;">
                <table class="custom-table" border="1">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Pemeriksaan</th>
                        <th>Nilai</th>
                        <th>Hasil Pemeriksaan</th>
                      </tr>
                    </thead>

                    <tbody>
                      <template v-for="(item, index) in data_print.data_pemeriksaan">
                        <tr v-if="item.paket_pemeriksaan_lab_id">
                          <td class="text-center">{{ index + 1 }}</td>
                          <td><b>{{ item.nama_paket_pemeriksaan }}</b></td>
                          <td colspan="2"></td>
                        </tr>
                        <tr v-for="data in item.sub_paket">
                          <td v-if="item.paket_pemeriksaan_lab_id"></td>
                          <td class="text-center" v-else>{{ index + 1 }}</td>

                          <td style="padding-left:30px;"><b>{{ data.nama_sub_pemeriksaan_lab }}</b></td>
                          <!-- <td v-else><b>{{ data.nama_paket_pemeriksaan }}</b></td> -->

                          <td>{{ data.hasil_pemeriksaan }} {{ data.satuan_pemeriksaan ? data.satuan_pemeriksaan : '' }}</td>
                          <td>{{ data.nilai_pemeriksaan }}</td>
                        </tr>
                      </template>
                    </tbody>
                </table>
            </div>

            <div class="w-100" style="margin-top: 2.5mm;">
                <h6>Keterangan : </h6>
            </div>

            <div class="w-100" style="margin-top: 10mm;">
                <table class="custom-table">
                    <tbody>
                        <tr>
                            <td style="width: 40%;">
                                <br/>
                                <h6 style="text-align: center;">Dokter/Tenaga Medis</h6>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <h6 v-if="data_print.dokter" style="text-align: center;">{{ data_print.dokter }}</h6>
                                <h6 v-else style="text-align: center;">{{ 'nama_dokter' }}</h6>
                            </td>
                            <td style="width: 20%;">&nbsp;</td>
                            <td style="width: 40%;">
                                <h6 style="text-align: center;">{{ $store.state.puskesmas.nama_kota }}, {{ $moment(data_print.tanggal_sampel).format("LL") }}</h6>
                                <h6 style="text-align: center;">Pemeriksa</h6>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <h6 v-if="data_print.pemeriksa" style="text-align: center;">{{ data_print.pemeriksa }}</h6>
                                <h6 v-else style="text-align: center;">{{ 'nama_pemeriksa' }}</h6>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";

export default {
  name: "input_paket",
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      data_pasien: {
        no_antrian: ""
      },
      data_pemeriksaan: {
        pemeriksaan_lab_id: "",
      },
      data_kunjungan_lab: null,
      option_pilihan: [
        {value: 1, text: 'Pilih Pasien'},
        {value: 1, text: 'Pilih Pemeriksaan'},
      ],
      option_kunjungan: [
        {value: 1, text: 'Baru'},
        {value: 1, text: 'Lama'},
      ],
      option_asuransi: [
        {value: 1, text: 'Umum'},
        {value: 1, text: 'BPJS Kesehatan'},
        {value: 1, text: 'Pemerintah Daeah Kota'},
      ],

      option_rujukan: [
        {value: 1, text: 'Sendiri'},
        {value: 1, text: 'Dokter'},
        {value: 1, text: 'Bidan'},
        {value: 1, text: 'Mantri'},
        {value: 1, text: 'Perawat'},
      ],
      list_paket: [],
      list_tindakan: [],
      list_kunjungan: [],
      tampungan: [],
      list_pemeriksaan: [],
      data_sampel: [],
      list_kunjungan_lab: [],
      busy: false,
      disabledMulai: false,
      data_print: {},
      printReady: false,
      id: null,
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    id_kunjungan_lab() {
      return this.$route.query ? this.$route.query.id_kunjungan_lab : null;
    },
    id_rm() {
      return this.$route.query ? this.$route.query.id_rm : null;
    },
    id_kunjungan() {
      return this.$route.query ? this.$route.query.id_kunjungan : null;
    },
    id_antrian() {
      return this.$route.query ? this.$route.query.id_antrian : null;
    },
    logo() {
      return this.$store.state.logo;
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
    },
  },
  created() {
    
    this.id = localStorage.getItem('idp')
      this.$socket.on('connect', () => {
        // console.log(this.id,'ini id');
        this.$socket.emit("joinRoom", this.id);
      })
      this.$socket.off("refresh_admin_lab")
      this.$socket.on("refresh_admin_lab", (data) => {
        console.log(data, "uhuyyyyyyyy");
        this.datanya = data
        this.busy = false
        this.$router.push({
            path: "/laboratorium/input_pemeriksaan_lab",
            query: { id_kunjungan_lab : this.id_kunjungan_lab, id_rm : this.id_rm, id_kunjungan : this.id_kunjungan, id_pemeriksaan : data.data.id },
        });
      });
      this.$socket.off("refresh_selesai_lab")
      this.$socket.on("refresh_selesai_lab", (data) => {
        console.log(data, "uhuyyyyyyyy");
        this.disabledMulai = true
        this.$store.commit('set_alert', {showing: true, variant: 'success', msg: "BERHASIL MENYELESAIKAN LAB"})
      });
      this.$socket.off("error")
      this.$socket.on("error", (data) => {
        console.log(data, 'ini data eror');
        this.$store.commit('set_alert', {showing: true, variant: 'danger', msg: data.message})
        this.busy = false
      })
    },
  activated() {
    // Set the initial number of items
    this.id = localStorage.getItem('idp')
    this.$socket.emit("joinRoom", this.id);
    this.getData()
  },
  methods: {

    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async getData(){
      let vm = this
      let data_pasien = await vm.$axios("kunjungan/details_by_id/"+ vm.id_kunjungan)
      console.log(data_pasien, 'ini data pasien');
      if(data_pasien.data.data.length){
        vm.data_pasien = data_pasien.data.data[0]
        vm.data_pasien.no_antrian = `${vm.data_pasien.initial}${vm.data_pasien.nomor_antrian}`
        vm.data_pasien.ttl = `${vm.data_pasien.tempat_lahir}, ${vm.$moment(vm.data_pasien.tanggal_lahir).format("DD-MM-YYYY")}`
        
      }
      let pemeriksaan = await vm.$axios("pemeriksaan_lab/details_by_kunjungan_lab_id/"+ vm.id_kunjungan_lab)
      console.log(pemeriksaan, 'ini data pemeriksaan');
      // vm.data_pemeriksaan = pemeriksaan.data.data[0]
      // vm.list_pemeriksaan = pemeriksaan.data.data[0].sub_pemeriksaan_lab
      // vm.data_sampel = pemeriksaan.data.data[0].sample_lab

      let kunjungan_lab = await vm.$axios("kunjungan_lab/details_by_id/"+ vm.id_kunjungan_lab)
      console.log(kunjungan_lab, 'ini data kunjungan_lab');

      if(pemeriksaan.data.data.length != 0){
        vm.data_pemeriksaan = pemeriksaan.data.data[0]
        vm.list_pemeriksaan = pemeriksaan.data.data[0].sub_pemeriksaan_lab
        vm.data_sampel = pemeriksaan.data.data[0].sample_lab
        if(vm.data_pemeriksaan.tanggal_selesai_pemeriksaan_lab){
          vm.data_pasien.tgl_selesai = vm.$moment(vm.data_pemeriksaan.tanggal_selesai_pemeriksaan_lab).format("DD-MM-YYYY HH:mm")
        }else {
          vm.data_pasien.tgl_selesai = '-'
        }
        if(vm.data_pemeriksaan.status_antrian == 9){
          vm.disabledMulai = true
        }
      }else{
        // console.log('msuk sini');
        vm.data_pemeriksaan = kunjungan_lab.data.data[0]
        vm.list_kunjungan_lab = kunjungan_lab.data.data[0].pool_sub_lab_kunjungan
      }
      // console.log(vm.list_pemeriksaan, 'ini ini ');
      let res1 = await vm.$axios.post("/paket_lab/list");
      vm.list_paket = res1.data.data
      let res2 = await vm.$axios.post("/tindakan_lab/list");
      vm.list_tindakan = res2.data.data

      for (let i = 0; i < vm.list_paket.length; i++) {
        let x = vm.list_paket[i];
        x.iconnya = "cil-plus"
        x.variantnya = "outline-dark"
        x.checked = false
      }
      for (let i = 0; i < vm.list_tindakan.length; i++) {
        let x = vm.list_tindakan[i];
        x.checked = false
        for (let j = 0; j < x.bulk_sub_tindakan_lab.length; j++) {
          let y = x.bulk_sub_tindakan_lab[j];
          y.checked = false
          y.disabled = false
        }
      }
    },
    toggleSelect(x) {
      console.log(x, 'ini x');
        let select = x.checked;
        x.bulk_sub_tindakan_lab.forEach(function (data) {
            data.checked = select;
        });
        this.setData()
        this.$forceUpdate()
    },
    assignData(x){
      let vm = this
      x.checked = !x.checked
      if(x.checked){
        for (let i = 0; i < x.bulk_paket_lab.length; i++) {
          let y = x.bulk_paket_lab[i];
          for (let j = 0; j < vm.list_tindakan.length; j++) {
            let z = vm.list_tindakan[j];
            for (let k = 0; k < z.bulk_sub_tindakan_lab.length; k++) {
              let p = z.bulk_sub_tindakan_lab[k];
              if(y.sub_tindakan_lab_id == p.sub_tindakan_lab_id){
                p.disabled = true
                p.checked = true
              }
            }
          }
        }
        this.setData()
        this.$forceUpdate()
      }else {
        for (let i = 0; i < x.bulk_paket_lab.length; i++) {
          let y = x.bulk_paket_lab[i];
          for (let j = 0; j < vm.list_tindakan.length; j++) {
            let z = vm.list_tindakan[j];
            for (let k = 0; k < z.bulk_sub_tindakan_lab.length; k++) {
              let p = z.bulk_sub_tindakan_lab[k];
              if(y.sub_tindakan_lab_id == p.sub_tindakan_lab_id){
                p.disabled = false
                p.checked = false
              }
            }
          }
        }
        this.setData()
        this.$forceUpdate()
      }
      
    },
    setData(){
      let vm = this
      vm.tampungan = []
      for (let i = 0; i < vm.list_paket.length; i++) {
        let x = vm.list_paket[i];
        if(x.checked == true){
          x.is_paket = true
          vm.tampungan.push(x)
          for (let j = 0; j < x.bulk_paket_lab.length; j++) {
            let y = x.bulk_paket_lab[j];
            for (let k = 0; k < vm.list_tindakan.length; k++) {
              let z = vm.list_tindakan[k];
              for (let l = 0; l < z.bulk_sub_tindakan_lab.length; l++) {
                let p = z.bulk_sub_tindakan_lab[l];
                if(y.sub_tindakan_lab_id == p.sub_tindakan_lab_id){
                  p.is_paket = false
                  p.harga_tindakan_lab = 0
                  vm.tampungan.push(p)
                }
              }
            }
          }
        }
      }
      for (let i = 0; i < vm.list_tindakan.length; i++) {
        let x = vm.list_tindakan[i];
        for (let j = 0; j < x.bulk_sub_tindakan_lab.length; j++) {
          let y = x.bulk_sub_tindakan_lab[j];
          if(y.checked == true){
            let sama = 0
            for (let k = 0; k < vm.tampungan.length; k++) {
              let z = vm.tampungan[k];
              if(y.sub_tindakan_lab_id == z.sub_tindakan_lab_id){
                sama +=1
              }
            }
            if(sama == 0){
              y.is_paket = false
              vm.tampungan.push(y)
            }
          }
        }
      }
      vm.$forceUpdate()
      console.log(vm.tampungan, 'ini data akhir');
    },
    pindahInput(){
        let vm = this
        let datanya = {}
        // datanya.tanggal_pemeriksaan_lab = vm.$moment(new Date()).format("YYYY-MM-DD HH:mm")
        // datanya.no_kartu = vm.data_pasien.no_kartu
        vm.busy = true
        datanya.rujukan_dari = vm.data_pemeriksaan.nama_poli
        datanya.nama_perujuk = vm.data_pemeriksaan.nama_dokter
        datanya.antrian_id = vm.id_antrian
        datanya.id = vm.data_pemeriksaan.kunjungan_lab_id
        datanya.puskesmas_id = vm.$store.state.puskesmas_id
        datanya.room_id = vm.id
        console.log(datanya);
        // vm.$axios.post("/pemeriksaan_lab/start_pemeriksaan_lab", datanya)
        // .then((res) => {
        //   console.log(res);
        //   if(res.data.status == 200 && res.data.message == "sukses"){
        //     // vm.$store.commit('set_alert', {variant: "danger",
        //     // msg: "SUKSES ",
        //     // showing: true,})
        //     vm.$router.push({
        //       path: "/laboratorium/input_pemeriksaan_lab",
        //       query: { id_kunjungan_lab : vm.id_kunjungan_lab, id_rm : vm.id_rm, id_kunjungan : vm.id_kunjungan, id_pemeriksaan : res.data.data.id },
        //     });
        //   }
        // })
        // .catch((err) => {
        //   console.log(err);
        // })
        vm.$socket.emit("start_pemeriksaan_lab", datanya);
        // vm.$router.push({
        //     path: "/laboratorium/input_pemeriksaan_lab",
        //     query: { id_kunjungan_lab : vm.id_kunjungan_lab, id_rm : vm.id_rm, id_kunjungan : vm.id_kunjungan },
        // });
    },
    selesaiPemeriksaan(){
      let vm = this
      let datanya = {}
      vm.busy = true
      datanya.antrian_id = vm.id_antrian
      datanya.status_antrian = 9
      datanya.room_id = vm.id
      console.log(datanya);
      vm.$socket.emit("selesai_lab", datanya);
    },
    goInput(){
      let vm = this
      vm.$router.push({
        path: "/laboratorium/input_pemeriksaan_lab",
        query: { id_kunjungan_lab : vm.id_kunjungan_lab, id_rm : vm.id_rm, id_kunjungan : vm.id_kunjungan, id_pemeriksaan : vm.data_pemeriksaan.pemeriksaan_lab_id },
      });
    },
    async printLab(val) {
      const vm = this
      let statusData = false
      if (val == 'hasil_pemeriksaan') {
        statusData = await vm.getDataPrint() 
      } else {
        statusData = true
      }
      const options_A4 = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
        '/style-paper-a4.css',
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: localStorage.getItem("nama_puskesmas"), // override the window title
      }

      if (statusData) {
        await vm.$htmlToPaper(val, options_A4);
      } else {
        vm.$store.commit("set_alert", { variant: "warning", msg: "TIDAK ADA DATA PEMERIKSAAN LABORATORIUM", showing: true });
      }
    },
    async getDataPrint() {
      const vm = this
      let x = {
        kunjungan_id: vm.id_kunjungan
      }
      try {
        let res = await vm.$axios.post("/cetak/cetak_hasil_pemeriksaan_laboratorium", x)
        if (res.data.status == 200) {
          console.log(res.data.data[0]);
          vm.data_print = res.data.data[0]
          vm.printReady = true
          return true
        }
      } catch (err) {
        console.log(err);
        vm.printReady = true
        return false
      }
    }
  },
};
</script>

<style scoped>
.table-info-pasien tr{
  border:none !important;
  padding:5px 10px !important;
}
.table-info-pasien td{
  border:none !important;
  padding:5px 10px !important;
}

.input-group-text{
  font-size: 12px;
}
.title-checkbox{
  font-weight: 600;
  color: #202020;
}
.card-columns {
  column-count: 4;
}

.card-columns .card-body{
  padding: 10px;
}
</style>
